import React, { useEffect, useRef } from 'react'
// import { Link } from 'gatsby'
import ReactWOW from 'react-wow';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ReactComponent as Arrow } from '../../images/icons/arrow_triangle-right_alt2.svg'
import FeaturePagination from '../../components/features/FeaturePagination'
import { Link, animateScroll as scroll } from "react-scroll";
import SignUpBtn from '../../components/features/SignUpBtn'

function Banner(props) {

    // useEffect(() => {

    //     // MOVE THE COMPUTER SCREEN OVER

    //     const tl = gsap.timeline({
    //       scrollTrigger: {
    //         trigger: animComputer.current,
    //         start: 'top top',
    //         // end: 'bottom bottom',
    //         endTrigger: animComputer.current,
    //         scrub: true,
    //         markers: true,
    //         id: "move-across",  }
    //     });
    //     tl.to(animComputer.current, {xPercent: 50})

    //   }, []); 

    return (
        <div>
            <section className="erp_banner_area_two">
                <div className="erp_shap"></div>
                <div className="erp_shap_two" style={{ background: 'url(/features/banner_shap.png) no-repeat scroll top left' }}></div>
                <div className="section_intro">
                    <div className="section_container">
                        <div className="intro">
                            <div className=" feature_intro_content">
                                <h1>{props.title}</h1>
                                <p>{props.subtitle}</p>

                                <SignUpBtn/>


                                {/* <ReactWOW animation="fadeInLeft" delay="0.9s">
                                    <Link className="general_banner_btn_orange btn_hover wow fadeInLeft" data-wow-delay="0.9s" to="/signup/" style={{ color: 'white' }}>Sign Up for Free</Link>
                                </ReactWOW> */}

                                <ReactWOW animation="fadeInRight" delay="0.9s">
                                    <Link
                                        className="general_banner_btn general_banner_btn_two_orange btn_hover add_margin"
                                        to="overview-cards"
                                        spy={true}
                                        smooth={true}
                                        offset={-200}
                                        duration={500}
                                    >Overview</Link>
                                </ReactWOW>


                                {/* <%--In modal popup please--%> */}
                            </div>
                        </div>
                        <ReactWOW animation="fadeInUp" delay="0.3s">
                            <div className="animation_img">
                                <div className="container feature_container">
                                    {props.mockupImg ? (
                                        <img
                                            className="feature_main_mockup"
                                            src={props.mockupImg}
                                            alt={props.title + " Banner"}
                                        // onLoad={ScrollTrigger.refresh()} 
                                        />) : null}
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                </div>


            </section>

            <FeaturePagination />
        </div>
    )
}

export default Banner
