import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"


export const query = graphql`
  query BrandedApparel {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
    }

    const bannerProps = {
        title: 'Branded Apparel',
        subtitle: `Business is often compared to professional sports. Like Vince Lombardi once said, ‘Nothing intimidates the competition more than seeing our boys in their green and gold!’ Okay, so he didn’t really say that, but he probably loved seeing his Packers rush out onto the field in their jerseys. Logos and brands tell a story – and who doesn’t love swag? Especially when it inspires interest and generates credibility for your company. That’s why we offer a massive selection of branded apparel to unite your team, keep everyone looking sharp and establish a sense of pride and community within and outside of your organization.`,
        featureName: 'behaviourObservations',
        rightButtonText: 'Why Certificate Tracking Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        mockupImg: "/features/branded-apparel/branded-banner.png"
    }

    const seo = {
        title: 'Workhub | Branded Apparel Custom Embroidery Recognition Progr',
        description: "Who doesn't love swag? Incentivize your employees & boost safety compliance by rewarding them with branded apparel.",
        image: '/features/branded-apparel/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
            title: "Optional",
            para: "Branded apparel is an optional but highly recommended part of our Recognition program intended to incentivize workers, inspire interest, and develop a sense of pride and credibility within and outside your organization. ",
        },
        {
            title: "Onboarding Boost",
            para: "A large portion of Recognition points will be earned during the onboarding process which can be immediately redeemed for a shirt and hat to welcome new employees to the team. ",
        },
        {
            title: "Huge Selection",
            para: "We offer a MASSIVE selection of high-quality apparel with sizes ranging from XS-3XL. The clothing comes in a handful of flattering shades that are designed to make your logo pop. ",
        },
        {
            title: "Don’t Purchase in Bulk",
            para: "Your company does not have to purchase sizes/styles by the dozen. Workers looking to redeem points can choose items in our Recognition catalog and only those selected items will be sent. ",
        },
        {
            title: "Convenient Shipping",
            para: "Selected apparel will be shipped directly from our embroidering/shipping department to your workers home in a care package with 4 additional bonus items. ",
        },
    ]

    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />

            <main className="feature-main">
                <section className="feature-section">
                    <div className="container" >

                    <section className="feature-section">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-6 feature-text push-down">


                                <h2>De Minimis….</h2>

                                <p>What on earth does that mean? De minimis is a Latin expression which means ‘pertaining to minimal things’, normally in the term <em>de minimis non curat lex</em> which is essentially: “the court refuses to consider trifling matters”. </p>

                                <p>You’re probably wondering how this relates to Rewards. Well, as you likely already know most employee benefits are taxable. However, the government recognizes some items and benefits as <strong>de minimis fringe benefits</strong> which possess low/no market value. In these cases, taxation is deemed trivial and unnecessary. So, what qualifies as a de minimis fringe benefit? You guessed it: branded apparel and swag. That means that your employees can enjoy spending their points without having it added to their income tax at the end of the year and you can continue to benefit from the boost in compliance.</p>

                            </div>

                            <div className="col-lg-6 feature-img">

                                <ReactWOW animation="fadeInRight" delay="0.9s">

                                    <img
                                        className="feature-img-left push-up"
                                        src="/features/branded-apparel/deminimis-two.jpg"
                                        alt="Workers happy to be repping their branded apparel"
                                    />

                                </ReactWOW>

                            </div>
                        </div>
                    </div>
                </section>


                        <div className="row switch-children">

                            <div className="col-lg-6 feature-img">
                                <ReactWOW animation="fadeInLeft" delay="0.9s">
                                    <img
                                        className="feature-img-right"
                                        src="/features/branded-apparel/branded-merch.png"
                                        alt="Stack of merch - Sweaters, tees and a hat."
                                    />
                                    </ReactWOW>
                            </div>


                            <div className="col-lg-6 feature-text">
                                <h2>Give us the goods.</h2>
                                <p>Like all components in our Recognition program, Branded Apparel is optional. However, most of our customers feel that it is a centerpiece of their organizations. Workers earn the bulk of their points during the onboarding process, which they can quickly redeem for a snazzy hat and jacket to make them feel like they are part of the team. After the onboarding process is complete your workers will continue to earn points by attending safety meetings, submitting audits and much more (it’s all integrated). Seeing the points tally-up encourages them to keep interacting with our system and drives up your workplace safety and compliance. Can’t beat that kind of representation, and you’re killing two birds (including perhaps, the dreaded black swan) with one stone!</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="logo-banner">


<ReactWOW animation="fadeInUp" delay="0.8s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\callaway.png' alt="Callaway Logo" />
    </div>

</ReactWOW>

<ReactWOW animation="fadeInDown" delay="0.6s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\coal.png' alt="Coal Harbour Logo" />
    </div>

</ReactWOW>

<ReactWOW animation="fadeInUp" delay="0.7s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\dryframe.png' alt=" Dryframe logo" />
    </div>

</ReactWOW>

<ReactWOW animation="fadeInDown" delay="0.8s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\gildan.png' alt="Gildan Logo" />
    </div>

</ReactWOW>

<ReactWOW animation="fadeInUp" delay="0.6s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\newera.png' alt="New era logo" />
    </div>

</ReactWOW>

<ReactWOW animation="fadeInDown" delay="0.7s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\ogio.png' alt="Ogio Logo" />
    </div>

</ReactWOW>

<div className='logo-grid-child'>
    <img src='\features\branded-apparel\sanmar.png' alt="SanMar logo" />
</div>

<ReactWOW animation="fadeInUp" delay="0.8s">

    <div className='logo-grid-child'>
        <img src='\features\branded-apparel\EB.jpg' alt="Eddie Bauer Logo" />
    </div>

</ReactWOW>


                </section>


                <section className="feature-section">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-6 feature-text push-down">


                                <h2>The options are endless (No, seriously).</h2>

                                <p>No one wants cheap looking, or flimsy apparel. That’s why our catalog is stocked full of stylish, high-quality items for men and women. We have jackets (jean, canvas, rain…it goes on), socks, polos, t-shirts, button-ups, long sleeves, hats, beanies, hoodies and almost every other sweater style you can imagine. Our apparel also comes in a handful of shades that are designed to make your logos pop! We regularly stock sizes small to 2XL and can order additional sizes (XS, 3XL, 4XL) per request within a few days. The clothing section of our catalog has quickly become the most popular part of our rewards program and we take pride in procuring an inventory of items your staff will love.</p>

                            </div>

                            <div className="col-lg-6 feature-img">

                                <ReactWOW animation="fadeInRight" delay="0.9s">

                                    <img
                                        className="feature-img-left push-up"
                                        src="/features/branded-apparel/branded-computer.png"
                                        alt="Rewards inventory screen on tablet"
                                    />

                                </ReactWOW>

                            </div>
                        </div>
                    </div>
                </section>



                <section className="feature-section">
                    <div className="container" >
                        <div className="row switch-children">

                            <div className="col-lg-6 feature-img">

                                <ReactWOW animation="fadeInRight" delay="0.9s">

                                    <img
                                        className="feature-img-right push-up"
                                        src="/features/branded-apparel/branded-tablet.png"
                                        alt="Rewards inventory screen on tablet"
                                    />

                                </ReactWOW>

                            </div>

                            <div className="col-lg-6 feature-text">

                                <h2>No fuss, no muss.</h2>

                                <p>Ordering and inventorying (yes, it’s a word) branded apparel through external vendors can be an absolute nightmare for many companies. Often, you are forced to order at least a dozen items when you only need 2 or 3 of a particular size/style. Remember the time Ed in Sales needed a 3XL but all you had left were 7 smalls? UGH! Poor Ed. Plus, no one wants to buy and store things they don’t need. We have our own embroidering/shipping staff (bless them) who will take care of all that for you. Your workers can select whatever they want and only those items will be sent directly to their house in a fancy little care package, which contains additional goodies to knock their socks off.</p>

                            </div>
                        </div>
                    </div>
                </section>


                {/* FEATURE OVERVIEW */}

                <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

            </main>
        </Layout>
    )
}

