import React, {useEffect, useState} from 'react';
import { Link } from "gatsby";
import ReactWOW from 'react-wow';

function SignUpBtn() {

    const [path, setPath] = useState("");

    useEffect(() => {


    setPath(window.location.pathname)

      
  });

    return (
        <>
        {path.includes('safety-training') ? 
        <ReactWOW animation="fadeInLeft" delay="0.9s">
            <Link 
            className="general_banner_btn_orange btn_hover wow fadeInLeft" 
            data-wow-delay="0.9s" 
            to="/features/training/" 
            style={{ color: 'white' }}>Training Courses</Link>
        </ReactWOW> :
        <ReactWOW animation="fadeInLeft" delay="0.9s">
            <Link 
            className="general_banner_btn_orange btn_hover wow fadeInLeft"
            data-wow-delay="0.9s"
            to="/signup/"
            style={{ color: 'white' }}>Sign Up for Free</Link>
        </ReactWOW>
        }
        </>
    )
}

export default SignUpBtn
